import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { delete_cookie } from "sfcookies";
import {bake_cookie} from "../read_cookie"
import axios from "axios";
import config from "../configs/config";

const Redirect = (props) => {
  let params = useParams();
  let history = useHistory();
  let fullEncStr = params.id;
  delete_cookie("pnc-rewards-token");
  delete_cookie("profileId");
  delete_cookie("ProgramCode");
  // sessionStorage.clear();
  // localStorage.clear();
  const URL = config.qa.node_url;
  let encArr;
  let token;
  let profileId;
  let ProgramCode;
  const sendLogToServer = async (logData) => {
    try {
      await axios.post(`${URL}save-log`, logData);
      console.log("log sent successfull");
    } catch (error) {
      console.error("log error:", error);
    }
  };
  try {
    if (fullEncStr !== 0) {
      encArr = fullEncStr.split("&&");
      token = encArr[0].toString();
      profileId = encArr[1].toString();
      ProgramCode = encArr[2].toString();
      bake_cookie("pnc-rewards-token", token);
      bake_cookie("profileId", profileId);
      bake_cookie("ProgramCode", ProgramCode);
    }
  } catch (error) {
    sendLogToServer({
      token,
      profileId,
      ProgramCode,
      errorLog: `Cookie Handle ERROR: ${JSON.stringify(error.message)}`,
    });
  }
  sendLogToServer({token, profileId, ProgramCode, errorLog: 'NO ERROR - SUCESS LOG' });
  history.push(`/`);

  return (
    <section className="pnc-help-section">
        <div className="loader"></div>
    </section>
  );
};

export default Redirect;
